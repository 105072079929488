<template>
  <div>
    <van-nav-bar class="nav nav-header-home" fixed z-index="999" :placeholder="true">
      <template v-slot:title>
        <span class="nav-title-text">扫码签到</span>
      </template>
      <template v-slot:left>
        <div class="icon-back" @click="toBack"></div>
      </template>
      <template v-slot:right>
        <div class="finish" @click="toBack">完成</div>
      </template>
    </van-nav-bar>

    <div class="div-center">
      <div v-show="isShow">
        <div class="active_img icon_error" v-if="standardList === 404"></div>
        <div class="active_img icon_success" v-else></div>
        <div class="span_success span_error" v-if="standardList === 404"> 签到失败</div>
        <div class="span_success" v-else>签到成功</div>
        <div class="Time_text h-28px flex mt-70px">
          <div class="line_left mr-8px"></div>
          <span class="text-light-20px" v-if="standardList === 404">未知编码</span>
          <span class="text-light-20px" v-else>当前时段任务</span>
          <div class="line_right ml-8px"></div>
          <div></div>
        </div>
        <div class="mt-24px" v-if="standardList === 404">系统中不存在或未登记，请确认！</div>
        <div class="mt-24px text-16px" v-if="standardList && standardList.length>0">
          <div class="m-16px" v-for="(item,index) of standardList" :key='index'>
            <van-field autosize v-model="item.standardName" type="textarea" disabled />
          </div>
        </div>
        <div v-if="!standardList">
          <div class="mt-24px"> 暂无任务</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import api from '@/api/index'

export default {
  components: { },
  data () {
    return {
      standardList: undefined,
      // { standardId: 1, standardName: '洗手间_保洁_环境日常洗手间_保洁_环境日常洗手间_保洁_环境日常洗_环境日常洗_环境日常洗_环境日常洗_环境日常洗_环境日常洗手间_保洁_环境日常洗手间_保洁_环境日常' },
      // { standardId: 2, standardName: '洗手间_asd阿萨德_环境日常' },
      // { standardId: 3, standardName: '洗手间_境日手间_环境日常' }
      isShow: false // 判断是否已经调用接口

    }
  },
  created () {
    this.jumpScan()
    console.log('数据接收@@@@', this.$route.query.qrInfo)
    console.log('this.$route.query: ', this.$route.query)
    // this.standardList = this.$route.query.standardList
    // console.log('this.standardList: ', this.standardList)
    // console.log('this.$route.query.standardList: ', this.$route.query.standardList)
  },
  methods: {
    toBack () {
      mobile.backWeb()
    },
    async jumpScan () {
      let date = new Date()// 当前时间
      let year = date.getFullYear() // 返回指定日期的年份
      let month = this.repair(date.getMonth() + 1)// 月
      let day = this.repair(date.getDate())// 日
      let scanDate = year + '-' + month + '-' + day
      let qrcode = this.$route.query.qrInfo
      const match = this.$route.query.qrInfo?.trim().match(/scene=([A-Z|a-z|0-9|-]*)/) // 适配小程序参数码
      if (match && match[1]) {
        qrcode = match[1]
      }
      const obj = {
        qrCode: qrcode,
        scanDate: scanDate
      }
      const data = await this.$http.post(api.signInFindStandard, obj)
      console.log('签到data: ', data)
      this.isShow = true
      this.standardList = data
    },
    repair (i) {
      if (i >= 0 && i <= 9) {
        return '0' + i
      } else {
        return i
      }
    }
  }
}
</script>

<style lang="less" scoped>
.finish{
  color:#3366FE;
  font-size:16px
}
.active_img{
  width: 105px;
  height: 105px;
  margin: 0 auto;
  margin-top: 50px;
}
.span_success{
  margin: 0 auto;
  margin-top: 15px;
  color:#13CE66;
  font-size: 24px;
}
.span_error{
  color:#FF4949;
}
.Time_text{
  align-items: center; /*垂直方向居中*/
  justify-content: center;  /*水平方向居中*/
}
.icon_success {
  background: url("../../../asset/icon_success.svg") no-repeat;
  background-size: cover;
}
.icon_error{
  background: url("../../../asset/icon_error.svg") no-repeat;
  background-size: cover;
}
.line_left, .line_right{
  width: 80px;
  height: 1px;
}
.line_left {
  background: -webkit-linear-gradient(left,#B8C1CF,#F2F2F2);
  background: linear-gradient(to left,#B8C1CF,#F2F2F2 );
}
.line_right {
  background: -webkit-linear-gradient(right,#B8C1CF,#F2F2F2);
  background: linear-gradient(to right,#B8C1CF,#F2F2F2 );
}
.nav {
  .nav-title-text {
    font-size: 18px;
    font-weight: 601;
    color: #222222;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../../../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
}
.div-center {
  text-align: center;
}
.standard_list {
  font-size: 16px;
  color: #8B8F9A;
}
.van-field {
  padding: 0;
  /deep/ .van-field__control {
    border: 0.5px solid #c5c5c5 !important;
    border-radius: 2px;
    padding: 5px 12px;
  }
  // &.textarea {
  //   /deep/ .van-field__control {
  //     height: auto;
  //   }
  // }
}
</style>
