var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        staticClass: "nav nav-header-home",
        attrs: { fixed: "", "z-index": "999", placeholder: true },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("span", { staticClass: "nav-title-text" }, [
                  _vm._v("扫码签到"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left",
            fn: function () {
              return [
                _c("div", {
                  staticClass: "icon-back",
                  on: { click: _vm.toBack },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "finish", on: { click: _vm.toBack } },
                  [_vm._v("完成")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "div-center" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShow,
                expression: "isShow",
              },
            ],
          },
          [
            _vm.standardList === 404
              ? _c("div", { staticClass: "active_img icon_error" })
              : _c("div", { staticClass: "active_img icon_success" }),
            _vm.standardList === 404
              ? _c("div", { staticClass: "span_success span_error" }, [
                  _vm._v(" 签到失败"),
                ])
              : _c("div", { staticClass: "span_success" }, [
                  _vm._v("签到成功"),
                ]),
            _c("div", { staticClass: "Time_text h-28px flex mt-70px" }, [
              _c("div", { staticClass: "line_left mr-8px" }),
              _vm.standardList === 404
                ? _c("span", { staticClass: "text-light-20px" }, [
                    _vm._v("未知编码"),
                  ])
                : _c("span", { staticClass: "text-light-20px" }, [
                    _vm._v("当前时段任务"),
                  ]),
              _c("div", { staticClass: "line_right ml-8px" }),
              _c("div"),
            ]),
            _vm.standardList === 404
              ? _c("div", { staticClass: "mt-24px" }, [
                  _vm._v("系统中不存在或未登记，请确认！"),
                ])
              : _vm._e(),
            _vm.standardList && _vm.standardList.length > 0
              ? _c(
                  "div",
                  { staticClass: "mt-24px text-16px" },
                  _vm._l(_vm.standardList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "m-16px" },
                      [
                        _c("van-field", {
                          attrs: {
                            autosize: "",
                            type: "textarea",
                            disabled: "",
                          },
                          model: {
                            value: item.standardName,
                            callback: function ($$v) {
                              _vm.$set(item, "standardName", $$v)
                            },
                            expression: "item.standardName",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            !_vm.standardList
              ? _c("div", [
                  _c("div", { staticClass: "mt-24px" }, [_vm._v(" 暂无任务")]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }